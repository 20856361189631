
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import { _CREATE, _VIEW } from '@shell/config/query-params';
import type { AKSDiskType, AKSNodePool, AKSPoolMode } from '../types/index';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import Taint from '@pkg/aks/components/Taint.vue';
import UnitInput from '@shell/components/form/UnitInput.vue';
import RadioGroup from '@components/Form/Radio/RadioGroup.vue';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import KeyValue from '@shell/components/form/KeyValue.vue';
import Banner from '@components/Banner/Banner.vue';

import { randomStr } from '@shell/utils/string';

export default defineComponent({
  name: 'AKSNodePool',

  components: {
    LabeledInput,
    LabeledSelect,
    Taint,
    UnitInput,
    RadioGroup,
    Checkbox,
    KeyValue,
    Banner
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    pool: {
      type:     Object as PropType<AKSNodePool>,
      required: true
    },

    vmSizeOptions: {
      type:    Array,
      default: () => []
    },

    // vm sizes are fetched in the parent component to avoid repeating the request for every node pool
    loadingVmSizes: {
      type:    Boolean,
      default: false
    },

    canUseAvailabilityZones: {
      type:    Boolean,
      default: true
    },

    validationRules: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    clusterVersion: {
      type:    String,
      default: ''
    },

    originalClusterVersion: {
      type:    String,
      default: ''
    }
  },

  data() {
    return {
      taints: (this.pool.nodeTaints || []).map((taint: String) => {
        return { taint, _id: randomStr() };
      }),
      osDiskTypeOptions:       ['Managed', 'Ephemeral'] as AKSDiskType[],
      modeOptions:             ['User', 'System'] as AKSPoolMode[],
      availabilityZoneOptions: [{ label: 'zone 1', value: '1' }, { label: 'zone 2', value: '2' }, { label: 'zone 3', value: '3' }],

      originalOrchestratorVersion: this.pool.orchestratorVersion
    };
  },

  watch: {
    'pool.enableAutoScaling'(neu) {
      if (!neu) {
        delete this.pool.minCount;
        delete this.pool.maxCount;
      } else {
        this.$set(this.pool, 'minCount', 1);
        this.$set(this.pool, 'maxCount', 3);
      }
    },

    'pool.vmSize'(neu) {
      if (neu) {
        this.$emit('vmSizeSet');
      }
    },

    validAZ(neu) {
      this.$set(this.pool, '_validAZ', neu);
      this.$emit('input');
    }
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    validAZ(): Boolean {
      return !this.pool.availabilityZones || !this.pool.availabilityZones.length || this.canUseAvailabilityZones;
    },

    isView() {
      return this.mode === _VIEW;
    },

    clusterWillUpgrade() {
      return this.originalClusterVersion !== this.clusterVersion;
    },

    // offer a k8s version upgrade if the node pool is not on the same version as the cluster and the cluster is not currently being upgraded
    upgradeAvailable(): boolean {
      if (this.mode === _CREATE || this.pool._isNewOrUnprovisioned) {
        return false;
      }

      return this.clusterVersion !== this.originalOrchestratorVersion && !!this.originalOrchestratorVersion;
    },

    willUpgrade: {
      get() {
        return this.upgradeAvailable && this.pool.orchestratorVersion === this.clusterVersion;
      },
      set(neu: boolean) {
        if (neu) {
          this.$set(this.pool, 'orchestratorVersion', this.clusterVersion);
        } else {
          this.$set(this.pool, 'orchestratorVersion', this.originalOrchestratorVersion);
        }
      }
    },
  },

  methods: {
    addTaint(): void {
      this.taints.push({ taint: '', _id: randomStr() });
      this.$set(this.pool, 'nodeTaints', this.taints.map((keyedTaint: any) => keyedTaint.taint));
      this.$emit('input');
    },

    updateTaint(keyedTaint: any, idx: any): void {
      this.taints[idx] = keyedTaint;
      this.$set(this.pool, 'nodeTaints', this.taints.map((keyedTaint: any) => keyedTaint.taint));
      this.$emit('input');
    },

    removeTaint(idx: number): void {
      const neu = [...this.taints];

      neu.splice(idx, 1).map((keyedTaint) => keyedTaint.taint);

      this.$set(this, 'taints', neu);
      this.$set(this.pool, 'nodeTaints', neu.map((taint) => taint.taint));
    },

    availabilityZonesSupport() {
      return this.validAZ ? undefined : this.t('aks.errors.availabilityZones');
    }
  },
});
